import { template as template_831d771b440943b98026af5d05f331cf } from "@ember/template-compiler";
const SidebarSectionMessage = template_831d771b440943b98026af5d05f331cf(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
