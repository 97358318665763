import { template as template_3f2e0eb45b6e4d6e8a904b1af38a2d54 } from "@ember/template-compiler";
const FKText = template_3f2e0eb45b6e4d6e8a904b1af38a2d54(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
